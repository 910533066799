<template>
    <router-view v-if="isPortal" />
    <section-loading v-else-if="isLoading" />
    <div v-else>
        <a-alert v-if="hasPaymentNotification">
            Confirm and save your Product Prices before continuing
        </a-alert>
        <Reseller :reseller="reseller" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { AAlert } from '@/components/AAlert';
import SectionLoading from '../SectionLoading.vue';
import Reseller from './Reseller';

import type { WhitelabelData } from '@/types/Whitelabel';

@Component({
    components: {
        SectionLoading,
        Reseller,
        AAlert
    }
})
export default class Whitelabel extends Vue {
    group = 'Visitor';

    reseller: WhitelabelData = {
        id: 0,
        business_name: '',
        product_name: '',
        reseller_booking_url: '',
        reseller_currency: '',
        subdomain: '',
        website: null,
        reseller_logo: null,
        reseller_profile_image: null,
        social_media: null,
        currency_symbol: '',
        currencies: {},
        resellerMinClientAuthoringCredit: 0,
        resellerMinClientAuthoringSub: 0,
        resellerMinClientSnapshotPrice: 0,
        resellerMinClientPremiumAmpCreditPrice: 0,
        resellerMinClientRecurringPremiumAmpCreditPrice: 0,
        resellerMinClientDefiCreditPrice: 0,
        resellerMinClientRecurringDefiCreditPrice: 0,
        resellerMinClientBlowPackPrice: 0,
        resellerMinClientRecurringBlowPackPrice: 0,
        resellerMinClientMsnPrice: 0,
        resellerMinClientRecurringMsnPrice: 0,
        resellerMinClientContentCampaignPrice: 0,
        resellerMinClientRecurringContentCampaignPrice: 0,
        resellerMinClientRecurringFourContentCampaignsPrice: 0,
        numPremium: 0,
        hasPremiumPurchase: false,
        numCryptoPremium: 0,
        numGuestPostPremium: 0,
        numMsnCredits: 0,
        isClientCabin: false,
        isAnnouncementEngine: false,
        artifactNamePure: '',
        artifactName: ''
    };

    isLoading = true;

    get hasPaymentNotification() {
        return new URL(window.location.href).searchParams.has('updatePrices');
    }

    get isPortal() {
        return this.$route.params.subsection === 'portal';
    }

    mounted() {
        this.setLoading();

        return this.getUserData()
            .then(({ data }) => {
                if (data) {
                    this.group = data.group;
                    this.reseller = {
                        ...data.reseller,
                        ...data.user,
                        currencies: data.currencies,
                        hasPremiumPurchase: data.credits.hasPremiumPurchase,
                        numCryptoPremium: data.credits.crypto,
                        numGuestPostPremium: data.credits.blow,
                        numMsnCredits: data.credits.msn
                    };
                }
            })
            .finally(this.setLoading.bind(this, false));
    }

    setLoading(isLoading = true) {
        this.$emit('loading', isLoading);
        this.isLoading = isLoading;
    }

    getUserData() {
        return this.$http
            .get('/users/panel')
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                return {};
            });
    }
}
</script>
